import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Divider, Tabs, Spin, Button } from 'antd';
import './style.css';
import SelectFilter from './SelectFilter';
import SliderFilter from './SliderFilter';
import SwitchFilter from './SwitchFilter';
import FilterTag from './FilterTag';
import ScatterPlot from './ScatterPlot';
import { metalColors, alloyColors, colorPalette } from './colors.jsx'

const InteractiveValidationDataPlots = () => {
    const [csvData, setCsvData] = useState([]);
    /*
        // All columns filters
        //Tabs and Blind
        'Type'
        'UTS/Yield'
        'Blind?'
    
        //Benchtop
        'Alloy/Grade'
        'Base Metal'
        'Processing Method'
        'Test Temperature (C)'
    
        //Portable
        'Outer Diameter (inches)'
        'Wall Thickness (inches)'
        'Alloy/Grade'
        'Weld Type'
        
    */
    // Benchtop States
    const [selectedAlloyBenchtop, setSelectedAlloyBenchtop] = useState([]);
    const [selectedBaseMetal, setSelectedBaseMetal] = useState([]);
    const [selectedProcessingMethod, setSelectedProcessingMethod] = useState([]);
    const [selectedTestTemperature, setSelectedTestTemperature] = useState([]);
    const [lastChangedBenchtopSelect, setLastChangedBenchtopSelect] = useState('Base Metal');

    // Portable States
    const [selectedOuterDiameterRange, setSelectedOuterDiameterRange] = useState([null, null]);
    const [selectedWallThicknessRange, setSelectedWallThicknessRange] = useState([null, null]);
    const [outerDiameterRange, setOuterDiameterRange] = useState({ min: 0, max: 100 });
    const [wallThicknessRange, setWallThicknessRange] = useState({ min: 0, max: 10 });
    const [selectedWeldType, setSelectedWeldType] = useState([]);
    const [selectedAlloyPortable, setSelectedAlloyPortable] = useState([]);
    const [selectedBlind, setSelectedBlind] = useState('Not Blind');
    const [lastChangedPortableSelect, setLastChangedPortableSelect] = useState('Alloy/Grade');

    // Type and UTS/Yield States
    const [selectedType, setSelectedType] = useState('Benchtop');
    const [selectedUTSYield, setSelectedUTSYield] = useState('Yield');
    const [columnFilters, setColumnFilters] = useState({});
    const [filteredDataBenchtop, setFilteredDataBenchtop] = useState([]);
    const [filteredDataPortable, setFilteredDataPortable] = useState([]);
    const [rbf, setRbf] = useState(new Date().getTime());

    const [enabledBenchtopFilters, setEnabledBenchtopFilters] = useState(false);
    const [enabledPortableFilters, setEnabledPortableFilters] = useState(false);


    useEffect(() => {
        const condition =
            selectedWeldType.length === 0 &&
            selectedAlloyPortable.length === 0
        setEnabledPortableFilters(!condition);
    }, [outerDiameterRange, selectedOuterDiameterRange, wallThicknessRange, selectedWallThicknessRange, selectedWeldType, selectedAlloyPortable, selectedBlind]);

    useEffect(() => {
        const condition = selectedAlloyBenchtop.length === 0 &&
            selectedBaseMetal.length === 0 &&
            selectedProcessingMethod.length === 0 &&
            selectedTestTemperature.length === 0;

        setEnabledBenchtopFilters(!condition);
    }, [selectedAlloyBenchtop, selectedBaseMetal, selectedProcessingMethod, selectedTestTemperature]);


    useEffect(() => {
        let unmounted = false;
        const fetchData = async () => {
            const response = await fetch('/Comparison Data.xlsx - Data.csv');
            const csvText = await response.text();

            Papa.parse(csvText, {
                header: true,
                dynamicTyping: true,
                complete: (result) => {
                    if (result.data && result.data.length > 0) {
                        // Convert string numbers to actual numbers
                        const convertToNumber = (value) => {
                            if (typeof value === "string" && /^(\d+),(\d+)$/.test(value)) {
                                return parseFloat(value.replace(",", "."));
                            }
                            return value;
                        };

                        const processedData = result.data.map(row => {
                            return Object.fromEntries(
                                Object.entries(row).map(
                                    ([key, value]) => [key, convertToNumber(value)]
                                )
                            );
                        });

                        const outerDiameterValues = processedData.map(d => d['Outer Diameter (inches)']);
                        const wallThicknessValues = processedData.map(d => d['Wall Thickness (inches)']);

                        const outerDiameterMax = Math.max(...outerDiameterValues);
                        const outerDiameterMin = Math.min(...outerDiameterValues);

                        const wallThicknessMax = Math.max(...wallThicknessValues);
                        const wallThicknessMin = Math.min(...wallThicknessValues);

                        setOuterDiameterRange({ min: outerDiameterMin, max: outerDiameterMax });
                        setWallThicknessRange({ min: wallThicknessMin, max: wallThicknessMax });

                        setSelectedOuterDiameterRange([outerDiameterMin, outerDiameterMax]);
                        setSelectedWallThicknessRange([wallThicknessMin, wallThicknessMax]);

                        setCsvData(processedData);

                        const uniqueColumnFilters = {};
                        Object.keys(processedData[0]).forEach((column) => {
                            uniqueColumnFilters[column] = [...new Set(processedData.map((row) => row[column]))];
                        });
                        // all posible values in filters, max min etc
                        setColumnFilters(uniqueColumnFilters);
                    }
                },
            });
        };
        fetchData();
        return () => {
            unmounted = true;
        };
    }, []);

    // Benchtop 
    useEffect(() => {
        let unmounted = false;
        if (csvData.length > 0) {
            handleBenchtopFilter();
        }
        return () => {
            unmounted = true;
        };
    }, [csvData,
        selectedBaseMetal, selectedAlloyBenchtop, selectedProcessingMethod, selectedTestTemperature,
        selectedType, selectedUTSYield]
    );

    // Portable 
    useEffect(() => {
        let unmounted = false;
        if (csvData.length > 0) {
            handlePortableFilter();
        }
        return () => {
            unmounted = true;
        }
    }, [csvData,
        selectedOuterDiameterRange, selectedWallThicknessRange, selectedAlloyPortable, selectedWeldType, selectedBlind,
        selectedType, selectedUTSYield
    ]
    );

    const filtersRefresh = () => {
        setRbf(new Date().getTime())
    }

    const handleBenchtopFilter = () => {
        const filtered = csvData.filter((row) => {
            // Benchtop conditions
            const conditionAlloy = selectedAlloyBenchtop.length === 0 || selectedAlloyBenchtop.includes(row['Alloy/Grade']);
            const conditionBaseMetal = selectedBaseMetal.length === 0 || selectedBaseMetal.includes(row['Base Metal']);
            const conditionProcessingMethod = selectedProcessingMethod.length === 0 || selectedProcessingMethod.includes(row['Processing Method']);
            const conditionTestTemperature = selectedTestTemperature.length === 0 || selectedTestTemperature.includes(row['Test Temperature (C)']);
            const conditionType = selectedType ? row['Type'] === selectedType : true;

            let conditionUTSYield = true;

            if (selectedUTSYield === 'UTS') {
                conditionUTSYield = row['PIP UTS'] !== null && row['Tensile UTS'] !== null;
            }

            if (selectedUTSYield === 'Yield') {
                conditionUTSYield = row['PIP YS'] !== null && row['Tensile YS'] !== null;
            }

            return (
                conditionType &&
                conditionAlloy &&
                conditionBaseMetal &&
                conditionProcessingMethod &&
                conditionTestTemperature &&
                conditionUTSYield
            );
        });

        setFilteredDataBenchtop(filtered);
    };

    const handlePortableFilter = () => {
        const filtered = csvData.filter((row) => {
            //Portable conditions
            const conditionOuterDiameter =
                (selectedOuterDiameterRange[0] !== null && selectedOuterDiameterRange[0] !== undefined) ? (
                    row['Outer Diameter (inches)'] >= selectedOuterDiameterRange[0] &&
                    row['Outer Diameter (inches)'] <= selectedOuterDiameterRange[1]
                ) : true;
            const conditionWallThickness =
                (selectedWallThicknessRange[0] !== null && selectedWallThicknessRange[0] !== undefined) ? (
                    row['Wall Thickness (inches)'] >= selectedWallThicknessRange[0] &&
                    row['Wall Thickness (inches)'] <= selectedWallThicknessRange[1]
                ) : true;
            const conditionAlloy = selectedAlloyPortable.length === 0 || selectedAlloyPortable.includes(row['Alloy/Grade']);
            const conditionWeldType = selectedWeldType.length === 0 || selectedWeldType.includes(row['Weld Type']);
            const conditionType = selectedType ? row['Type'] === selectedType : true;
            const conditionBlind = selectedBlind === "Not Blind" ? row['Blind?'] === "Not Blind" : true;
            return (
                conditionAlloy &&
                conditionType &&
                conditionOuterDiameter &&
                conditionWallThickness &&
                conditionWeldType &&
                conditionBlind
            );
        });

        setFilteredDataPortable(filtered);
    };

    const onChangeFilterTab = (key) => {
        setSelectedType(key);
    };

    const onChangePlotTab = (key) => {
        setSelectedUTSYield(key);
    };

    function correctName(string) {
        let newString = string;
        if (selectedType === 'Portable') {
            newString = newString.replace('Alloy/Grade', 'Grade');
        }

        if (selectedType === 'Benchtop') {
            newString = newString.replace('Alloy/Grade', 'Alloy');
        }
        const patternsToRemove = [' - Drop Down'];
        patternsToRemove.forEach(pattern => {
            newString = newString.replace(pattern, '');
        });
        return newString.trim();
    }

    let data;

    switch (selectedType) {
        case 'Benchtop':
            data = filteredDataBenchtop;
            break;
        case 'Portable':
            data = filteredDataPortable;
            break;
        default:
            data = [];
    }

    const maxValue = selectedType === 'Portable' ? 800 : 2500;

    function getTooltipFields(selectedType, selectedUTSYield) {
        let baseFields = [];
        if (selectedType === 'Benchtop') {
            baseFields = ['Alloy/Grade', 'Base Metal', 'Processing Method'];
        } else if (selectedType === 'Portable') {
            baseFields = ['Outer Diameter (inches)', 'Wall Thickness (inches)', 'Alloy/Grade', 'Weld Type'];// to test , 'Blind?'
        }
        if (selectedUTSYield === 'UTS') {
            baseFields.push('PIP UTS', 'Tensile UTS');
        } else if (selectedUTSYield === 'Yield') {
            baseFields.push('PIP YS', 'Tensile YS');
        }
        return baseFields;
    }

    const config = {
        data: data,
        height: 300,
        xField: selectedUTSYield === 'UTS' ? 'PIP UTS' : 'PIP YS',
        yField: selectedUTSYield === 'UTS' ? 'Tensile UTS' : 'Tensile YS',
        colorField: (selectedType === 'Benchtop') ? lastChangedBenchtopSelect : lastChangedPortableSelect,
        shapeField: 'Blind?',
        shape: (datum) => {
            if (selectedBlind === 'Not Blind') {
                return 'circle';
            } else {
                if (datum['Blind?'] === 'Blind') {
                    return 'square';
                } else { return 'circle'; }
            }
        },
        color: (datum) => {
            const selectedKey = selectedType === 'Benchtop' ? lastChangedBenchtopSelect : lastChangedPortableSelect;
            const colorMap = colorPalette[selectedKey];

            if ((selectedType === 'Benchtop' && !enabledBenchtopFilters) ||
                (selectedType !== 'Benchtop' && !enabledPortableFilters)) {
                return '#e1e1e1';
            }

            if (colorMap) {
                // detect new items with no color !colorMap[datum[selectedKey]] && console.log(datum[selectedKey]);
                return colorMap[datum[selectedKey]] || "#5B8FF9";
            }
            return "#5B8FF9";
        },
        size: 4,
        animate: false,
        legend: false,
        pointStyle: (datum) => {
            let fillColor;
            if (selectedType === 'Benchtop') {
                fillColor = enabledBenchtopFilters ? metalColors[datum['Base Metal']] : '#e1e1e1';
            } else {
                fillColor = enabledPortableFilters ? alloyColors[datum['Alloy/Grade']] : '#e1e1e1';
            }
            return {
                stroke: '#3A4C60',
                lineWidth: 1,
                fill: fillColor
            };
        },

        xAxis: {
            min: 0,
            max: selectedType === 'Portable' ? 800 : 2500,
            grid: {
                line: {
                    style: {
                        stroke: '#00000033',
                        lineWidth: 1,
                    },
                },
            },
            label: {
                formatter: (v) => {
                    const value = Number(v);
                    if (value > 0 && value < 1) {
                        return ''
                    } else {
                        return `${value.toFixed(0)} `
                    }
                },
                style: {
                    fontSize: 10,
                    color: '#3A4C60',
                    opacity: '0.5'
                },
            },
            title: {
                text: selectedUTSYield === 'UTS' ? 'Tensile UTS (MPa)' : 'Tensile Yield Stress (MPa)',
                style: {
                    fontSize: 10,
                    color: '#3A4C60',
                    opacity: '0.8'
                },
            },
        },
        yAxis: {
            min: 0,
            max: selectedType === 'Portable' ? 800 : 2500,
            grid: {
                line: {
                    style: {
                        stroke: '#00000033',
                        lineWidth: 1,
                    },
                },
            },
            label: {
                formatter: (v) => {
                    const value = Number(v);
                    if (value > 0 && value < 1) {
                        return ''
                    } else {
                        return `${value.toFixed(0)} `
                    }
                },
                style: {
                    fontSize: 10,
                    color: '#3A4C60',
                    opacity: '0.5'
                },
            },
            title: {
                text: selectedUTSYield === 'UTS' ? 'PIP UTS (MPa)' : 'PIP Yield Stress (MPa)',
                style: {
                    fontSize: 10,
                    color: '#3A4C60',
                    opacity: '0.8'
                },
            },
        },
        annotations: [
            {
                type: 'line',
                start: [0, 0],
                end: [maxValue, maxValue],
                style: {
                    stroke: '#828282'
                }
            },
            {
                type: 'line',
                start: [0, 0],
                end: [maxValue-maxValue/10, (maxValue + 0.1 * maxValue)-maxValue/10],
                style: {
                    stroke: 'rgba(0, 0, 0, 0.4)',
                    lineDash: [4, 4]
                }
            },
            {
                type: 'line',
                start: [0, 0],
                end: [maxValue, maxValue - 0.1 * maxValue],
                style: {
                    stroke: 'rgba(0, 0, 0, 0.4)',
                    lineDash: [4, 4]
                }
            },
            {
                type: 'html',
                position: selectedType === 'Portable' ? [570, maxValue] : [1750, maxValue], 
                html: '<div style="background: rgba(0, 0, 0, 0.60);font-size:11px; padding: 1px 4px; border-radius: 5px; color: #FFFFFF;">+10%</div>',
              },
              {
                type: 'html',
                position: selectedType === 'Portable' ? [726, 640] : [2260, 1960], 
                html: '<div style="background: rgba(0, 0, 0, 0.60);font-size:11px; padding: 1px 4px; border-radius: 5px; color: #FFFFFF;">-10%</div>',
              }
        ],
        tooltip: {
            fields: getTooltipFields(selectedType, selectedUTSYield),
            customContent: (x, items) => {
                return (
                    <>
                        <div style={{ paddingLeft: 0 }}>
                            {<table
                                style={{
                                    paddingLeft: 0,
                                    listStyleType: 'none',
                                }}
                            ><tbody>
                                    {items.map((item) => {
                                        let value = item.value;
                                        if (Number.isFinite(Number(item.value))) {
                                            value = Number(Number(item.value).toFixed(2));
                                            value = value % 1 === 0 ? value.toFixed(0) : value.toString();
                                        }
                                        return (
                                            <tr key={item.name + '' + item.value}>
                                                <td >
                                                    <span style={{ backgroundColor: item.color, width: '8px', height: '8px', display: 'inline-block', marginBottom: '6px', borderRadius: 50, fontSize: 1, lineHeight: 8 }}>&nbsp;</span>
                                                </td>
                                                <td style={{ textAlign: 'left', whiteSpace: 'nowrap', fontSize: 14 }}>
                                                    {correctName(item.name)}
                                                </td>
                                                <td style={{ textAlign: 'right', whiteSpace: 'nowrap', fontSize: 14 }}>
                                                    {value}
                                                </td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody></table>}
                        </div>
                    </>
                );
            },
        },
    };

    const BenchtopFilters = () => {
        return (
            <div >
                <SelectFilter key={rbf + "1"} data={filteredDataBenchtop} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedBenchtopSelect} selectedKey={'Base Metal'} selectedFilter={selectedBaseMetal} setSelectedFilter={setSelectedBaseMetal} placeholder='Base Metal' />
                <SelectFilter key={rbf + "2"} data={filteredDataBenchtop} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedBenchtopSelect} selectedKey={'Alloy/Grade'} selectedFilter={selectedAlloyBenchtop} setSelectedFilter={setSelectedAlloyBenchtop} placeholder='Alloy' />
                <SelectFilter key={rbf + "3"} data={filteredDataBenchtop} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedBenchtopSelect} selectedKey={'Processing Method'} selectedFilter={selectedProcessingMethod} setSelectedFilter={setSelectedProcessingMethod} placeholder='Processing method' />
                <SelectFilter key={rbf + "4"} data={filteredDataBenchtop} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedBenchtopSelect} selectedKey={'Test Temperature (C)'} selectedFilter={selectedTestTemperature} setSelectedFilter={setSelectedTestTemperature} placeholder='Room temperature' />
            </div>
        )
    }

    const PortableFilters = () => {
        return (
            <div>
                <SliderFilter
                    min={outerDiameterRange.min}
                    max={outerDiameterRange.max}
                    value={selectedOuterDiameterRange}
                    onChange={setSelectedOuterDiameterRange}
                    label='Outer diameter (in)'
                    step={0.1}
                />
                <Divider plain></Divider>
                <SliderFilter
                    min={wallThicknessRange.min}
                    max={wallThicknessRange.max}
                    value={selectedWallThicknessRange}
                    onChange={setSelectedWallThicknessRange}
                    label='Wall thickness (in)'
                    step={0.01}
                />
                <Divider plain></Divider>
                <SelectFilter key={rbf + "5"} data={filteredDataPortable} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedPortableSelect} selectedKey={'Alloy/Grade'} selectedFilter={selectedAlloyPortable} setSelectedFilter={setSelectedAlloyPortable} placeholder='Grade' />
                <SelectFilter key={rbf + "6"} data={filteredDataPortable} filtersRefresh={filtersRefresh} setLastChangeFilter={setLastChangedPortableSelect} selectedKey={'Weld Type'} selectedFilter={selectedWeldType} setSelectedFilter={setSelectedWeldType} placeholder='Weld type' />

                <Button
                    disabled={!enabledPortableFilters}
                    size="small" onClick={resetPortableFilters}>Clear All</Button>
                <div style={{ height: '90px' }}></div>
                <SwitchFilter value={selectedBlind} onChange={setSelectedBlind} label={selectedBlind === 'Not Blind' ? 'Show blind trial data' : 'Hide blind trials'} />
            </div>
        )
    }

    const resetBenchtopFilters = () => {
        setSelectedAlloyBenchtop([]);
        setSelectedBaseMetal([]);
        setSelectedProcessingMethod([]);
        setSelectedTestTemperature([]);
    };

    const resetPortableFilters = () => {
        setSelectedOuterDiameterRange([outerDiameterRange.min, outerDiameterRange.max]);
        setSelectedWallThicknessRange([wallThicknessRange.min, wallThicknessRange.max]);
        setSelectedAlloyPortable([]);
        setSelectedWeldType([]);
        setSelectedBlind('Not Blind');
    };

    const itemsFilterTab = [
        {
            key: 'Benchtop',
            label: 'PLX-Benchtop',
        },
        {
            key: 'Portable',
            label: 'PLX-Portable',
        },
    ];

    const itemsPlotTab = [
        {
            key: 'Yield',
            label: 'Yield',
            children: <ScatterPlot key={selectedBlind + 'key'} config={config} />,
        },
        {
            key: 'UTS',
            label: 'UTS',
            children: <ScatterPlot key={selectedBlind + 'key'} config={config} />,
        },
    ];

    return (
        csvData.length > 0 ? (
            <div className="data-plots-container">
                <div className="item filters">
                    <Tabs defaultActiveKey="Benchtop" items={itemsFilterTab} onChange={onChangeFilterTab} />
                    <div style={{ display: selectedType === 'Benchtop' ? 'block' : 'none' }}>
                        {BenchtopFilters()}
                        <Button
                            disabled={!enabledBenchtopFilters}
                            size="small"
                            onClick={resetBenchtopFilters}>Clear All</Button>
                    </div>

                    <div style={{ display: selectedType === 'Portable' ? 'block' : 'none' }}>
                        {PortableFilters()}
                    </div>
                </div>
                <div className="item plot">
                    <Tabs defaultActiveKey="Yield" items={itemsPlotTab} onChange={onChangePlotTab} className='right-tab' />

                    <div style={{ display: 'flex', justifyContent: 'flex-end', visibility: (selectedBlind === 'Blind' && selectedType === 'Portable') ? 'visible' : 'hidden' }}>

                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'transparent',
                                border: '1px solid #828282',
                                marginRight: '5px'
                            }}></div>
                            <div style={{ fontSize: 12, color: '#828282' }}>Blind</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'transparent',
                                border: '1px solid #828282',
                                borderRadius: '50%',
                                marginRight: '5px'
                            }}></div>
                            <div style={{ fontSize: 12, color: '#828282' }}>Not Blind</div>
                        </div>
                    </div>
                </div>
                <div className="item filter-results">
                    <div style={{ display: selectedType === 'Benchtop' ? 'block' : 'none' }}>
                        <div>
                            <FilterTag
                                key={rbf + "7"}
                                selectedFilter={selectedBaseMetal}
                                setSelectedFilter={setSelectedBaseMetal}
                                selectedType={selectedType}
                                isGray={lastChangedBenchtopSelect !== 'Base Metal'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedBenchtopSelect}
                            />
                            <FilterTag
                                key={rbf + "8"}
                                selectedFilter={selectedAlloyBenchtop}
                                setSelectedFilter={setSelectedAlloyBenchtop}
                                selectedType={selectedType}
                                isGray={lastChangedBenchtopSelect !== 'Alloy/Grade'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedBenchtopSelect}
                            />
                            <FilterTag
                                key={rbf + "9"}
                                selectedFilter={selectedProcessingMethod}
                                setSelectedFilter={setSelectedProcessingMethod}
                                selectedType={selectedType}
                                isGray={lastChangedBenchtopSelect !== 'Processing Method'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedBenchtopSelect}
                            />
                            <FilterTag
                                key={rbf + "10"}
                                selectedFilter={selectedTestTemperature}
                                setSelectedFilter={setSelectedTestTemperature}
                                selectedType={selectedType}
                                isGray={lastChangedBenchtopSelect !== 'Test Temperature (C)'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedBenchtopSelect}
                            /></div>
                    </div>
                    <div style={{ display: selectedType === 'Portable' ? 'block' : 'none' }}>
                        <div>
                            <FilterTag
                                key={rbf + "11"}
                                selectedFilter={selectedAlloyPortable}
                                setSelectedFilter={setSelectedAlloyPortable}
                                selectedType={selectedType}
                                isGray={lastChangedPortableSelect !== 'Alloy/Grade'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedPortableSelect}
                            />
                            <FilterTag
                                key={rbf + "12"}
                                selectedFilter={selectedWeldType}
                                setSelectedFilter={setSelectedWeldType}
                                selectedType={selectedType}
                                isGray={lastChangedPortableSelect !== 'Weld Type'}
                                filtersRefresh={filtersRefresh}
                                lastChangedSelect={lastChangedPortableSelect}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (<div className="data-plots-container loading"><Spin /></div>)
    );
};

export default InteractiveValidationDataPlots;

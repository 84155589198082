import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const SelectFilter = ({ data, filtersRefresh, setLastChangeFilter, selectedKey, selectedFilter, setSelectedFilter, placeholder }) => {
    const [tempOptions, setTempOptions] = useState([]);

    useEffect(() => {
        let unmounted = false;
        const options = [...new Set(data.map((item) => item[selectedKey]))];
        let sortedOptions = options.filter((option) => option && option.toString().trim() !== "").sort();
        sortedOptions = sortedOptions.filter(option => selectedFilter.includes(option));
        setSelectedFilter(sortedOptions)
        setTempOptions(sortedOptions);
        return () => { unmounted = true };
    }, []);

    const tagRender = (props) => {
        return (
            <>
                {props.value}
                <span className='comma'>, </span>
            </>
        );
    };

    const handleFilterChange = (selectedValues) => {
        setSelectedFilter(selectedValues);
        if (selectedValues.length > 0) {
            setLastChangeFilter(selectedKey);
        }
    };

    const handleDropdownVisibleChange = (open) => {
        if (open) {
            const options = [...new Set(data.map((item) => item[selectedKey]))];
            const sortedOptions = options.filter((option) => option && option.toString().trim() !== "").sort();
            setTempOptions(sortedOptions);
        } else {
            filtersRefresh(selectedKey);
        }
    };

    return (
        <Select
            mode='multiple'
            onChange={handleFilterChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            allowClear={true}
            onClear={() => filtersRefresh(selectedKey)}
            value={selectedFilter.filter(option => tempOptions.includes(option))}
            className='select-filter'
            tagRender={tagRender}
            maxTagTextLength={3}
            size='large'
            placeholder={placeholder}
            maxTagPlaceholder={null}
            removeIcon={null}
            showSearch={false}
            options={tempOptions.map((item) => ({ label: item, value: item }))}
        />
    );
};

export default SelectFilter;
import React from 'react';
import { Tag } from 'antd';
import { colorPalette } from './colors.jsx'

const FilterTag = ({ selectedFilter, setSelectedFilter, selectedType, isGray, filtersRefresh, lastChangedSelect }) => {
    const handleTagClose = (value) => {
        const updatedFilter = selectedFilter.filter((item) => item !== value);
        setSelectedFilter(updatedFilter);
        setTimeout(() => {
            filtersRefresh();
        }, 10);
    };
    const getColor = (value) => {
        const colorMap = colorPalette[lastChangedSelect];
        if (colorMap) {
            return colorMap[value] || "#5B8FF9";
        }
        return "#5B8FF9";
    };

    return (
        <div key={selectedFilter.length} style={{ filter: isGray ? 'grayscale(100%)' : 'none' }}>
            {selectedFilter.map((value, index) => (
                <Tag key={index} closable onClose={() => handleTagClose(value)}
                    color={getColor(value, index)}
                >
                    {value}
                </Tag>
            ))}
        </div>
    );
};

export default FilterTag;
import React from 'react';
import { Switch, Row, Col } from 'antd';

const SwitchFilter = ({ value, onChange, label }) => {
  const handleSliderChange = (newValue) => {
    onChange(newValue ? "Blind" : "Not Blind");
  };

  return (
    <>
      <Row>
        <Col flex="auto" className='form-label'>
          {label}
        </Col>
        <Col flex="none">
          <Switch
            checked={value === "Blind"} 
            onChange={(value) => handleSliderChange(value)}
            size='small'
          />
        </Col>
      </Row>
    </>
  );
};

export default SwitchFilter;

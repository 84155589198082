
export const metalColors = {
    "Copper": "#FFC47E",
    "Steel": "#D4F2F2",
    "Nickel": "#B7F29A",
    "Aluminium": "#BDD3E1",
    "Titanium": "#F3E7FF",
    "Other": "#9FCCFF",
    "Chromium": "#FFCCC7",
    "Pipe Steel": "#FFD700"
};

export const alloyColors = {
    "Pure Cu": "#FF5733",
    "Maraging 250 ": "#33FF57",
    "Waspalloy": "#5733FF",
    "X65": "#FF33B8",
    "6082": "#FF8C33",
    "6000 series": "#33FF8C",
    "4340": "#8C33FF",
    "Beryllium copper Alloy 25": "#FF338C",
    "Maraging 350": "#33FFCC",
    "Al 2014": "#CC33FF",
    "Al 2618": "#FFFF33",
    "15-5 PH": "#33FFFF",
    "17-4 PH": "#FF3333",
    "Maraging 300": "#3333FF",
    "AlSi10Mg": "#FF9633",
    "316L Stainless Steel": "#33FF96",
    "Copper Chromium Zirconium C18150": "#9633FF",
    "Scalmalloy": "#FF3396",
    "Ti64": "#96FF33",
    "Al 4008": "#33FF63",
    "HSLA Steel": "#6333FF",
    "C-Mn Steel": "#FF6333",
    "Martensitic Steel": "#33FF39",
    "Carbon Steel": "#FF3933",
    "Ti-48Al-2Cr-2Nb": "#3933FF",
    "FV607 Stainless Steel": "#33FFAA",
    "Magnesium ": "#AA33FF",
    "Cobalt Chromium": "#FFAA33",
    "X40Cr13": "#FF33AA",
    "S650": "#AAFF33",
    "Al 5754": "#33FFAA",
    "6111": "#AA33FF",
    "Al 2024": "#AAAA33",
    "EM12K": "#FF33FF",
    "E111T1-K3M": "#33FF33",
    "Al 43500": "#FF0000",
    "DP780": "#00FF00",
    "6063": "#0000FF",
    "CGI": "#FFFF00",
    "Ductile cast iron": "#00FFFF",
    "EN 10243 grade F": "#FF00FF",
    "4003 Ferritic Stainless": "#C2C2F0",
    "1.4547 austenitic stainless": "#FFC2C2",
    "Al 5182": "#C2FFC2",
    "1.4410 Super Duplex Stainless Steel": "#FF90C2",
    "Al 5251": "#C290FF",
    "Aluminium Casting Alloy LM25": "#FFC290",
    "Flake cast iron": "#90FFC2",
    "316 Stainless Steel": "#C2FF90",
    "Nickel Alloy 718": "#90C2FF",
    "410 Stainless Steel": "#FF9040",
    "4130": "#40FF90",
    "Alloyed ABD-900AM": "#9040FF",
    "Not Reported": "#FF4040",
    "B": "#40FF40",
    "X46": "#4040FF",
    "X42": "#FFD940",
    "X70": "#40FFD9",
    "X52": "#D940FF",
    "X60": "#FF40D9",
    // added 22/01/2024
    "Al-Si (unknown)": "#9891c4",
    "Invar 36": "#91bed2",
    "K500": "#9dbab3",
    "Magnesium": "#ad53eb",
    "Maraging 250": "#d07bee",
    "Ni ATI720": "#d692d8",
    "Ni RR1000": "#bffcf9",
    "Silicon Iron Bronze": "#84bcf6",
    "Ti 6246": "#a8e06d",
    "Ti17": "#65da90",
    // added 02/02/2024
    "Ductile Cast Iron": "#3E7C47",
    "EN 10243 Grade F": "#7ED3C1",
    "4003 Ferritic Stainless Steel": "#D3B17D",
    "1.4547 Austenitic Stainless Steel": "#C17DD3",
    "Al LM25": "#FFA347",
    "Flake Cast Iron": "#C4C4C4",
    "312 ABD-900AM": "#9DBA63"
};

export const processingMethodColors = {
    "Extruded": "#FF6347",
    "Forged": "#7FFFD4",
    "Rolled": "#DC143C",
    "AM - LPBF": "#DAA520",
    "AM - Other": "#8FBC8F",
    "HIP": "#2E8B57",
    "Wrought": "#BDB76B",
    "Cast": "#6B8E23",
    "Weld": "#808080",
    "AM - other": "#FF69B4"
};

export const testTemperatureColors = {
    "Room": "#1E90FF",
    "100": "#20B2AA",
    "150": "#3CB371",
    "200": "#F0E68C",
    "250": "#825c72",
    "250": "#F0E68C",
    "300": "#FFDAB9",
    "400": "#FFA07A",
    "500": "#D2691E"
};

export const weldTypeColors = {
    "SMLS": "#DB7093",
    "ERW": "#EE82EE",
    "DSAW": "#FFA500",
    "VAM": "#FFC0CB", // VAM is absent in the data 22/01/2024
    "FLASH": "#DDA0DD",
    "SAW": "#B0C4DE",
    "Unknown": "#8A2BE2",
    "Welded": "#A0522D",
    "LF-ERW": "#5F9EA0",
    "ERW-HF": "#9ACD32"
};

export const colorPalette = {
    'Alloy/Grade': alloyColors,
    'Base Metal': metalColors,
    'Processing Method': processingMethodColors,
    'Test Temperature (C)': testTemperatureColors,
    'Weld Type': weldTypeColors,
};

import React from 'react';
import { Slider, InputNumber, Row, Col } from 'antd';

const SliderFilter = ({ min, max, value, onChange, label, step }) => {
    const handleSliderChange = (newValue) => {
        onChange(newValue);
    };

    const handleInputNumberChange = (inputValue, inputIndex) => {
        const newValue = [...value];
        newValue[inputIndex] = inputValue;
        onChange(newValue);
    };

    return (
        <>
            <Row>
                <div className="form-label" >{label}</div>
                <Col span={24}>
                    <Slider
                        range
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={handleSliderChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <InputNumber
                        min={min}
                        max={max}
                        step={step}
                        style={{ width: '100%' }}
                        value={value[0]}
                        onChange={(value) => handleInputNumberChange(value, 0)}
                    />
                </Col>
                <Col span={8}></Col>
                <Col span={8}>
                    <InputNumber
                        min={min}
                        max={max}
                        step={step}
                        style={{ width: '100%' }}
                        value={value[1]}
                        onChange={(value) => handleInputNumberChange(value, 1)}
                    />
                </Col>
            </Row>
        </>
    );
};

export default SliderFilter;

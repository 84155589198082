import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Button from "../components/shared/Button/Button";
import React from "react";
import Layout from "../components/Layout";
import { styled } from "../stitches/stitches.config";
import Info1 from "../images/info_1.png";
import Info2 from "../images/info_2.png";
import Info3 from "../images/info_3.png";

import {
    detailSmall,
    paragraphTextSmall,
    heading100,
    heading150,
    heading200,
    heading300,
    heading500,
    paragraphTextLarge,
    paragraphTextMedium,

} from "../stitches/combinedStyles";
import InteractiveValidationDataPlots from "../components/interactiveValidationDataPlots";


const seo = {
    description:
        "Interactive Validation Data of our PLX-Benchtop and Portable Devices",
};

const title = `Interactive Material Data of our Devices`;
const description = `Explore comparison data for thousands of PIP tests across\ndifferent materials, alloys and processing methods.`

const bookButton = {
    text: "Book a trial",
    path: "https://plastometrex-6034553.hs-sites.com/-df211dc3-e9d6-4251-8c4d-43afb8cf9f77",
}

const exploreButton = {
    text: "Explore case studies",
    path: "/case-studies",
}

const InteractiveValidationData = () => {
    //return false
    return (
        <Layout seo={seo} backgroundColorUnderneath="white" backgroundColor="white">
            <Container>
                <Title>{title}</Title>
                {/* <Description>{description}</Description> */}
                <DesktopDiv>
                    <DesktopContainer>
                        <img style={{
                            width: '260px',
                            position: 'relative',
                            left: '-285px',
                            top: '199px'
                        }}
                            src={Info1}
                            alt={'info1'}
                        />
                        <img style={{
                            width: "145px",
                            position: 'relative',
                            top: '279px',
                            left: '434px'
                        }}
                            src={Info2}
                            alt={'info2'}
                        />
                    </DesktopContainer>
                    <InteractiveValidationDataPlots />
                    <div style={{ display: "flex", marginTop: '65px' }}>
                        <img style={{ width: "190px", marginRight: '20px' }}
                            src={Info3}
                            alt={'info3'}
                        />
                        <Link to={bookButton.path}>
                            <Button size="large" tier="primary" arrow fullWidth>
                                {bookButton.text}
                            </Button>
                        </Link>
                        <Link to={exploreButton.path}>
                            <Button size="medium" tier="secondary" arrow>
                                {exploreButton.text}
                            </Button>
                        </Link>
                    </div>
                </DesktopDiv>
            </Container>
        </Layout>
    );
};

export default InteractiveValidationData;

const Container = styled("div", {
    margin: "auto",
    width: '100%',
    background: 'linear-gradient(to bottom, #F9F9FB 0px, #F9F9FB 610px, #FFFFFF 610px)',
    "@md": {
        width: "100%",
        paddingBottom: 60,
        marginTop: 0,
    },
});

const Title = styled("h1", {
    ...heading150,
    marginBottom: 16,
    marginTop: 0,
    paddingTop: "10px",
    whiteSpace: "pre-line",
    fontWeight: 700,
    letterSpacing: "-0.4px",
    textAlign: "center",
    lineHeight: "28px",
    "@md": {
        ...heading300,
        fontSize: 37,
        lineHeight: "41px",
        marginTop: 0,
        marginBottom: 10,
        fontWeight: 700,
        letterSpacing: "-1px",
        textAlign: "center",
        paddingTop: 32
    },
});

const Description = styled("div", {
    ...paragraphTextLarge,
    color: "$black100",
    whiteSpace: "pre-line",
    textAlign: "center",
    opacity: 0.4,
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "-0.758px",
    "@md": {
        textAlign: "center",
    },
});

const DesktopContainer = styled("div", {
    display: "none",
    "@md": {
        display: "block",
    },
})

const DesktopDiv = styled("div", {
    marginTop: '10px',
    "@md": {
        width: '684px',
        margin: '0 auto',
        marginTop: '-77px'
    },
})